import React, { useState, onChange } from "react";
import PageWrapper from "../components/PageWrapper";

/* eslint-enable no-unused-vars */
import Newsletter from "../sections/landing2/Newsletter";
import EmailGate from "../utils/emailGate"

import { useForm, ValidationError } from "@formspree/react";
import { Button, Modal, Form } from "react-bootstrap";

const CPG = () => {
  const isBrowser = typeof window !== "undefined"
  function useLocalState(localItem) {
    const [loc, setState] = useState(() => {
      if (isBrowser) { return localStorage.getItem(localItem); }
      else { return true; }
    });
    function setLoc(bool) {
      if (isBrowser) { return localStorage.setItem(localItem, bool); }
      else { return setState(bool); }
    }
    return [loc, setLoc]
  }

  const [hasFilled, setFilled] = useLocalState("hasFilled");
  const [show, setShow] = useState(!hasFilled);
  const [state, handleSubmitForm] = useForm("xbjwqywb");

  const ResponseMsg = () => {
    if (state.succeeded) {
      setShow(false)
      setFilled(true)
      return <p> </p>;
    } else {
      setFilled(false)
      return <p> </p>;
    }
  };
  return (
    <>


      <PageWrapper
        headerConfig={{
          theme: "dark",
          isFluid: true,
          darkClass: "bg-gradient-4",
          buttonText: "Get started"
        }}
        footerConfig={{
          theme: "light",
          // style: "style1", //style1, style2
          style: "style5", //style1, style
        }}
      >
        <div css={`width:100%; height:800px; `}>
          <EmailGate />
          <iframe src="https://pixel-install.pearcommerce.com/" css={`width:100%; height:100%;`} allow="clipboard-read; clipboard-write"></iframe>
        </div>
        <div className="gradient-sections-wrapper bg-default-2 bg-pattern pattern-4">
          <Newsletter />
        </div>
      </PageWrapper>
    </>
  );
};
export default CPG;
